import { useMutation } from "@tanstack/react-query";
import { BillingManagementService, DocumentsService } from "api";
import { FileUtils, SnackbarUtils } from "utils";
import { useIsLoadingMutation } from "hooks/utils";

export const useDownloadDoctorPaymentReportQuery = () => {
  const mutation = useMutation({
    mutationKey: [BillingManagementService.downloadPaymentReport.queryKey],
    mutationFn: ({ doctorPaymentReportId }: { doctorPaymentReportId: string }) => BillingManagementService.downloadPaymentReport.request(doctorPaymentReportId)
  });

  const downloadDocumentHandler = (doctorPaymentReportId: string) => {
    mutation
      .mutateAsync({ doctorPaymentReportId })
      .then(data => {
        FileUtils.downloadFile(data.data, data.fileName);
        SnackbarUtils.success("Downloaded file successfully!");
      })
      .catch(() => {
        SnackbarUtils.error("Failed to download file! Please try again.");
      });
  };

  const { isLoading } = useIsLoadingMutation(DocumentsService.download.queryKey);

  return {
    ...mutation,
    downloadDocumentHandler,
    isLoading
  };
};
