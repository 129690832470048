import { BillingManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";

export const useDoctorPaymentReportsListQuery = () => {
  const query = usePaginatedDataTableQuery({
    queryFn: BillingManagementService.doctorPaymentReports.request,
    queryKey: BillingManagementService.doctorPaymentReports.queryKey
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
