import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { FormLayout } from "layout";

const PatientFolderDetailsPage = WithLoadable(lazy(() => import("pages/patients/PatientFolderDetailsPage")));
const PatientProfilePage = WithLoadable(lazy(() => import("pages/patients/PatientProfilePage")));
const PatientsListPage = WithLoadable(lazy(() => import("pages/patients/PatientsListPage")));
const PatientCreatePage = WithLoadable(lazy(() => import("pages/patients/PatientCreatePage")));
const PatientUpdatePage = WithLoadable(lazy(() => import("pages/patients/PatientUpdatePage")));

export const PatientsRoutes: RouteObject = {
  path: AppRoutes.patients.root,
  element: (
    <AuthorizationGuard
      permissions={[AppPermissions.PatientFile.Actions.View]}
    />
  ),
  children: [
    {
      index: true,
      element: <PatientsListPage />
    },
    {
      path: `${AppRoutes.patients.profile}/:patientId`,
      children: [
        { index: true, element: <PatientProfilePage /> },
        {
          path: `${AppRoutes.folders.root}/:folderId`,
          element: <PatientFolderDetailsPage />
        }
      ]
    }
  ]
};

export const PatientFormsRoutes: RouteObject = {
  path: AppRoutes.patients.root,
  element: <FormLayout />,
  children: [
    {
      path: AppRoutes.patients.create,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.PatientFile.Actions.Create]}
        />
      ),
      children: [{ index: true, element: <PatientCreatePage /> }]
    },
    {
      path: `${AppRoutes.patients.update}/:patientId`,
      element: (
        <AuthorizationGuard
          permissions={[AppPermissions.PatientFile.Actions.Update]}
        />
      ),
      children: [{ index: true, element: <PatientUpdatePage /> }]
    }
  ]
};
