import { useInfiniteQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useAppointmentsForPaymentDistributionLookupQuery = (search?: string, selectedItemsIds?: string[]) => {
  const query = useInfiniteQuery(
    [LookupService.appointmentsForPaymentDistribution.queryKey, search, selectedItemsIds?.join(",")],
    ({ pageParam = 1 }) => {
      const queryParam = new URLSearchParams();
      if (search) queryParam.append("searchTerm", search ?? "");
      queryParam.append("pageNumber", (+pageParam).toString());
      queryParam.append("pageSize", "50");
      if (selectedItemsIds?.length) {
        selectedItemsIds.forEach(id => queryParam.append("selectedItemsIds", id));
      }

      return LookupService.appointmentsForPaymentDistribution.request(queryParam.toString());
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) return undefined;
        return pages.length + 1;
      }
    }
  );

  return {
    ...query
  };
};
