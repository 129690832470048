import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { AppointmentPaymentDistributionLookupListItemDto, EAppointmentType, PaymentProfileTransactionForReadDto } from "types";

type DistributeOnAppointmentsProps = {
  paymentTransactions: Map<string, AppointmentPaymentDistributionLookupListItemDto>;
  onDeleteAppointment: (id: string) => void;
  onAddAppointments: (appointments: Map<string, AppointmentPaymentDistributionLookupListItemDto>) => void;
  onDeleteAllAppointments: () => void;
  getAppointmentsArray: () => AppointmentPaymentDistributionLookupListItemDto[];
};

const DistributeOnAppointmentsContext = createContext<DistributeOnAppointmentsProps>({
  paymentTransactions: new Map(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDeleteAppointment: id => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAddAppointments: appointments => {},
  onDeleteAllAppointments: () => {},
  getAppointmentsArray: () => []
});

type DistributeOnAppointmentsContextProviderProps = PropsWithChildren<{
  defaultTransactions?: PaymentProfileTransactionForReadDto[];
}>;
export function DistributeOnAppointmentsContextProvider({ children, defaultTransactions }: DistributeOnAppointmentsContextProviderProps) {
  const [paymentTransactions, setPaymentTransactions] = useState<Map<string, AppointmentPaymentDistributionLookupListItemDto>>(new Map());

  useEffect(() => {
    if (defaultTransactions) {
      const items = new Map<string, AppointmentPaymentDistributionLookupListItemDto>();
      defaultTransactions.forEach(transaction => {
        items.set(transaction.appointmentFinanceId!, {
          id: transaction.id!,
          doctorFullName: transaction.doctorName ?? "",
          patientFullName: transaction.patientName ?? "",
          dateOfService: transaction.dateOfService ?? "",
          type: transaction.appointmentType ?? EAppointmentType.InitialEvaluation,
          expectedAmount: transaction.amount,
          financeId: transaction.appointmentFinanceId
        });
      });
      setPaymentTransactions(items);
    }
  }, [defaultTransactions]);

  const onDeleteAppointment = (id: string) => {
    const items = new Map(paymentTransactions);
    items.delete(id);
    setPaymentTransactions(items);
  };

  const onAddAppointments = (appointments: Map<string, AppointmentPaymentDistributionLookupListItemDto>) => {
    const items = new Map(appointments);
    setPaymentTransactions(items);
  };

  const onDeleteAllAppointments = () => {
    setPaymentTransactions(new Map());
  };

  const getAppointmentsArray = (): AppointmentPaymentDistributionLookupListItemDto[] => {
    return Array.from(paymentTransactions.values());
  };

  return (
    <DistributeOnAppointmentsContext.Provider
      value={{
        paymentTransactions,
        onDeleteAppointment,
        onAddAppointments,
        onDeleteAllAppointments,
        getAppointmentsArray
      }}
    >
      {children}
    </DistributeOnAppointmentsContext.Provider>
  );
}

export const useDistributeOnAppointmentsContext = () => useContext(DistributeOnAppointmentsContext);
