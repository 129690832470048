import { ApiURLs, AxiosInstance, httpClient } from "api/core";
import {
  AppointmentFinanceForListDtoPaginatedList,
  DoctorPaymentReportForListDtoPaginatedList,
  PaginatedDataTable,
  PaymentForCreateUpdateDto,
  PaymentForListDto,
  PaymentProfileForReadDto,
  PaidDateForUpdateDto,
  AppointmentFinanceForReadDto,
  PaymentTransactionForListDtoPaginatedList
} from "types";

const URL = ApiURLs.billingManagement;

async function getPayments(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<PaymentForListDto>>(`${URL}/payments?${queryParams}`);
}

async function createPayment(payment: PaymentForCreateUpdateDto) {
  return await httpClient.post<void>(`${URL}/payments`, payment);
}

async function getPaymentDetails(paymentId: string) {
  return await httpClient.get<PaymentProfileForReadDto>(`${URL}/payments/${paymentId}`);
}
async function updatePayment({ paymentId, payment }: { paymentId: string; payment: PaymentForCreateUpdateDto }) {
  return await httpClient.put<PaymentProfileForReadDto>(`${URL}/payments/${paymentId}`, payment);
}
async function getAppointmentFinances(queryParams: string) {
  return await httpClient.get<AppointmentFinanceForListDtoPaginatedList>(`${URL}/appointment-finances?${queryParams}`);
}
async function setExpectedAmount({ appointmentFinanceId, expectedAmount }: { appointmentFinanceId: string; expectedAmount: string }) {
  return await httpClient.put<string>(`${URL}/appointments/${appointmentFinanceId}/expected-amount`, { expectedAmount });
}
async function setDepositDate({ appointmentFinanceId, depositDate }: { appointmentFinanceId: string; depositDate: string }) {
  return await httpClient.put<string>(`${URL}/appointments/${appointmentFinanceId}/deposit-date`, { depositDate });
}
async function generatePayroll(endDate: string) {
  return await httpClient.post<string>(`${URL}/payrolls`, { endDate });
}

async function getDoctorPaymentReports(queryParams: string) {
  return await httpClient.get<DoctorPaymentReportForListDtoPaginatedList>(`${URL}/doctor-payment-reports?${queryParams}`);
}

async function addPaidDate(data: PaidDateForUpdateDto) {
  return await httpClient.put<string>(`${URL}/payments/paid-date`, data);
}

async function overridePaidDate(data: PaidDateForUpdateDto) {
  return await httpClient.put<string>(`${URL}/payments/override-paid-date`, data);
}

async function downloadPaymentReport(doctorPaymentReportId: string) {
  const res = await AxiosInstance.get<Blob>(`${URL}/doctor-payment-reports/${doctorPaymentReportId}/document`, {
    responseType: "blob"
  });

  // @ts-expect-error
  const contentDisposition = res.headers.get("content-disposition");
  const regex = /filename\*=UTF-8''(.+)/;
  const match = contentDisposition.match(regex);
  let decodedFileName = "default-filename.xlsx";
  if (match && match[1]) {
    decodedFileName = decodeURIComponent(match[1]);
  } else {
    console.info("File name not found");
  }
  return {
    data: res.data,
    fileName: decodedFileName
  };
}

async function getAppointmentFinanceProfile(appointmentFinanceId: string) {
  return await httpClient.get<AppointmentFinanceForReadDto>(`${URL}/appointment-finances/${appointmentFinanceId}`);
}

async function getAppointmentFinancePaymentTransactions(queryParams: string) {
  return await httpClient.get<PaymentTransactionForListDtoPaginatedList>(`${URL}/payment-transactions?${queryParams}`);
}
export const BillingManagementService = {
  list: {
    request: getPayments,
    queryKey: "payments-list"
  },
  create: {
    request: createPayment,
    mutationKey: "create-payment"
  },
  update: {
    request: updatePayment,
    mutationKey: "update-payment"
  },
  details: {
    request: getPaymentDetails,
    queryKey: "get-payment-details"
  },
  appointmentFinances: {
    request: getAppointmentFinances,
    queryKey: "appointment-finances-list"
  },
  setExpectedAmount: {
    request: setExpectedAmount,
    mutationKey: "set-expected-amount"
  },
  setDepositDate: {
    request: setDepositDate,
    mutationKey: "set-deposit-date"
  },
  generatePayroll: {
    request: generatePayroll,
    mutationKey: "generate-payroll"
  },
  doctorPaymentReports: {
    request: getDoctorPaymentReports,
    queryKey: "doctor-payment-reports-list"
  },
  addPaidDate: {
    request: addPaidDate,
    mutationKey: "add-paid-date"
  },
  downloadPaymentReport: {
    request: downloadPaymentReport,
    queryKey: "download-payment-report"
  },
  overridePaidDate: {
    request: overridePaidDate,
    mutationKey: "override-paid-date"
  },
  appointmentFinanceProfile: {
    request: getAppointmentFinanceProfile,
    queryKey: "appointment-finance-profile"
  },
  appointmentFinancePaymentTransactions: {
    request: getAppointmentFinancePaymentTransactions,
    queryKey: "appointment-finance-payment-transactions"
  }
};
