import { useMutation } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { ApiError, PaymentForCreateUpdateDto, type PaymentTransactionForCreateUpdateDto } from "types";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";

export const useCreatePaymentMutation = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: [BillingManagementService.create.mutationKey],
    mutationFn: BillingManagementService.create.request,
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Payment created successfully!");
      navigate(-1);
    }
  });

  const calculateRemainingAmount = (collectedAmount: number, paymentTransactions: PaymentTransactionForCreateUpdateDto[]) => {
    const total = +collectedAmount || 0;
    const usedAmount =
      paymentTransactions?.reduce(
        (acc, transaction) => acc + (+transaction.amount || 0),
        0
      ) || 0;
    return total - usedAmount;
  };

  const onCreatePayment = (payment: PaymentForCreateUpdateDto) => {
    const remaining = calculateRemainingAmount(payment.collectedAmount, payment.paymentTransactions);
    if(remaining < 0) {
      SnackbarUtils.error("The total distributed amount across appointments must not exceed the total payment amount.");
      return;
    }
    if(remaining > 0) {
      SnackbarUtils.error("The total distributed amount across appointments must not be less than the total payment amount.");
      return;
    }
    mutation.mutate(payment);
  };

  const { isLoading } = useIsLoadingMutation(
    BillingManagementService.create.mutationKey
  );

  return {
    ...mutation,
    onCreatePayment,
    isLoading
  };
};
