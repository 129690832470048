import { ApiURLs, httpClient } from "api/core";
import {
  LookupListItemDto,
  ApplicationRoleLookupListItemDto,
  SpecialityLookupListItemDto,
  PatientFileLookupListItemDto,
  BackOfficeTeamLookupListDto,
  MedicalTeamLookupListDto,
  AdjusterLookupListItemDto,
  AttorneyLookupListItemDto,
  AppointmentPaymentDistributionLookupListItemDtoPaginatedList,
  RoleWithManagerRolesLookupListItemDto,
  PaymentLookupListItemDtoPaginatedList,
  DoctorPaymentReportLookupListItemDtoPaginatedList
} from "types";

import { store } from "stores";
import { PatientFileLookupListItemDtoPaginatedList } from "types/api-v2";

const URL = ApiURLs.LookUp;

async function getRoles() {
  const roles = await httpClient.get<ApplicationRoleLookupListItemDto[]>(`${URL}/roles`);
  store.rolesStore.setRoles(roles);
  return roles;
}

async function getUserGroups() {
  return await httpClient.get<LookupListItemDto[]>(`${URL}/user-groups`);
}

async function getLocations() {
  return await httpClient.get<LookupListItemDto[]>(`${URL}/locations`);
}

async function getUserByRoleName(roleName: string) {
  return await httpClient.get<LookupListItemDto[]>(`${URL}/users`, {
    params: {
      roleName
    }
  });
}

async function getPatients() {
  return await httpClient.get<PatientFileLookupListItemDto[]>(`${URL}/patient-files`);
}

async function getPaginatePatients(query: string) {
  return await httpClient.get<PatientFileLookupListItemDtoPaginatedList>(`${URL}/patient-files?${query}`);
}

async function getBodyParts() {
  return await httpClient.get<LookupListItemDto[]>(`${URL}/body-parts`);
}

async function getSpecialities() {
  return await httpClient.get<SpecialityLookupListItemDto[]>(`${URL}/specialities`);
}

async function getUsersBackOffice() {
  return await httpClient.get<BackOfficeTeamLookupListDto>(`${URL}/users-backoffice-team`);
}

async function getMedicalOfficeTeam() {
  return await httpClient.get<MedicalTeamLookupListDto>(`${URL}/users-medical-team`);
}

async function getAdjusters(name: string) {
  return await httpClient.get<AdjusterLookupListItemDto[]>(`${URL}/adjusters?adjusterName=${name}`);
}

async function getAttorneys(name: string) {
  return await httpClient.get<AttorneyLookupListItemDto[]>(`${URL}/attorneys?attorneyName=${name}`);
}

async function appointmentsForPaymentDistribution(queryParams: string) {
  return await httpClient.get<AppointmentPaymentDistributionLookupListItemDtoPaginatedList>(`${URL}/appointments-for-payment-distribution?${queryParams}`);
}
async function appointmentsForDuplicatePaymentDistribution(queryParams: string) {
  return await httpClient.get<AppointmentPaymentDistributionLookupListItemDtoPaginatedList>(
    `${URL}/appointments-for-duplicate-payment-distribution?${queryParams}`
  );
}

async function getRolesWithManagersRoles() {
  return await httpClient.get<RoleWithManagerRolesLookupListItemDto[]>(`${URL}/roles-with-manager-roles`);
}

async function getPayments(queryParams: string) {
  return await httpClient.get<PaymentLookupListItemDtoPaginatedList>(`${URL}/payments?${queryParams}`);
}

async function doctorPaymentReports(queryParams: string) {
  return await httpClient.get<DoctorPaymentReportLookupListItemDtoPaginatedList>(`${URL}/doctor-payment-reports?${queryParams}`);
}

export const LookupService = {
  roles: {
    request: getRoles,
    queryKey: "rolesList"
  },
  userGroups: {
    request: getUserGroups,
    queryKey: "userGroupsList"
  },
  users: {
    request: getUserByRoleName,
    queryKey: "getUserByRoleName"
  },
  specialities: {
    request: getSpecialities,
    queryKey: "getSpecialities"
  },
  locations: {
    request: getLocations,
    queryKey: "getLocationsLookups"
  },
  bodyParts: {
    request: getBodyParts,
    queryKey: "getBodyParts"
  },
  patients: {
    request: getPatients,
    queryKey: "getPatients"
  },
  usersBackOffice: {
    request: getUsersBackOffice,
    queryKey: "getUsersBackOffice"
  },
  usersMedical: {
    request: getMedicalOfficeTeam,
    queryKey: "getMedicalOfficeTeam"
  },
  adjusters: {
    request: getAdjusters,
    queryKey: "get-adjusters"
  },
  attorneys: {
    request: getAttorneys,
    queryKey: "get-attorneys"
  },
  appointmentsForPaymentDistribution: {
    request: appointmentsForPaymentDistribution,
    queryKey: "appointments-for-payment-distribution"
  },
  appointmentsForDuplicatePaymentDistribution: {
    request: appointmentsForDuplicatePaymentDistribution,
    queryKey: "appointments-for-duplicate-payment-distribution"
  },
  paginatePatients: {
    request: getPaginatePatients,
    queryKey: "getPaginatePatients"
  },
  rolesWithManagersRoles: {
    request: getRolesWithManagersRoles,
    queryKey: "roles-with-manager-roles"
  },
  payments: {
    request: getPayments,
    queryKey: "payments"
  },
  doctorPaymentReports: {
    request: doctorPaymentReports,
    queryKey: "doctor-payment-reports"
  }
};
