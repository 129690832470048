import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { RouteObject } from "react-router-dom";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const ActiveReportProfilePage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportProfilePage")));
const ActiveReportListPage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportListPage")));
const ActiveReportPreFolderDetailsPage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportPreFolderDetailsPage")));
const ActiveReportPostFolderDetailsPage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportPostFolderDetailsPage")));
const ActiveReportNoticesFolderDetailsPage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportNoticesFolderDetailsPage")));
const ActiveReportCreatePage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportCreatePage")));
const ActiveReportUpdatePage = WithLoadable(lazy(() => import("pages/active-reports/ActiveReportUpdatePage")));

export const ActiveReportRoutes: RouteObject = {
  path: AppRoutes.activeReports.root,
  element: <AuthorizationGuard permissions={[AppPermissions.ActiveReports.Actions.View]} />,
  children: [
    {
      index: true,
      element: <ActiveReportListPage />
    },
    {
      path: `${AppRoutes.activeReports.profile}/:activeReportId`,
      children: [
        { index: true, element: <ActiveReportProfilePage /> },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.preEvaluationFolder}`,
          element: <ActiveReportPreFolderDetailsPage />
        },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.postEvaluationFolder}`,
          element: <ActiveReportPostFolderDetailsPage />
        },
        {
          path: `${AppRoutes.folders.root}/:folderId/${AppRoutes.folders.notices}`,
          element: <ActiveReportNoticesFolderDetailsPage />
        }
      ]
    }
  ]
};

export const ActiveReportFormsRoutes: RouteObject = {
  path: AppRoutes.activeReports.root,
  element: <FormLayout />,
  children: [
    {
      path: AppRoutes.activeReports.create,
      element: <AuthorizationGuard permissions={[AppPermissions.ActiveReports.Actions.Create]} />,
      children: [{ index: true, element: <ActiveReportCreatePage /> }]
    },
    {
      path: `${AppRoutes.activeReports.update}/:activeReportId`,
      element: <AuthorizationGuard permissions={[AppPermissions.ActiveReports.Actions.Update]} />,
      children: [{ index: true, element: <ActiveReportUpdatePage /> }]
    }
  ]
};
