import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, PaidDateForUpdateDto } from "types";

export const useAddPaidMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [BillingManagementService.addPaidDate.mutationKey],
    mutationFn: BillingManagementService.addPaidDate.request,
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: async () => {
      SnackbarUtils.success("Paid Date has been added successfully");
      await queryClient.invalidateQueries({
        queryKey: [BillingManagementService.doctorPaymentReports.queryKey]
      });
    }
  });

  const onAddPaidDateAmount = (data: PaidDateForUpdateDto) => {
    return mutation.mutateAsync(data);
  };

  const { isLoading } = useIsLoadingMutation(BillingManagementService.addPaidDate.mutationKey);

  return {
    ...mutation,
    onAddPaidDateAmount,
    isLoading
  };
};
