import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

export const useAddDepositMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [BillingManagementService.setDepositDate.mutationKey],
    mutationFn: BillingManagementService.setDepositDate.request,
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: async () => {
      SnackbarUtils.success("Deposit Date added successfully");
      await queryClient.invalidateQueries({
        queryKey: [BillingManagementService.appointmentFinances.queryKey]
      });
    }
  });

  const onSetDepositAmount = ({ appointmentFinanceId, depositDate }: { appointmentFinanceId: string; depositDate: string }) => {
    return mutation.mutateAsync({ appointmentFinanceId, depositDate });
  };

  const { isLoading } = useIsLoadingMutation(BillingManagementService.setDepositDate.mutationKey);

  return {
    ...mutation,
    onSetDepositAmount,
    isLoading
  };
};
