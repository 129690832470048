function PreviewIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 21C4.0875 21 3.73438 20.8531 3.44063 20.5594C3.14688 20.2656 3 19.9125 3 19.5V4.5C3 4.0875 3.14688 3.73438 3.44063 3.44063C3.73438 3.14688 4.0875 3 4.5 3H19.5C19.9125 3 20.2656 3.14688 20.5594 3.44063C20.8531 3.73438 21 4.0875 21 4.5V19.5C21 19.9125 20.8531 20.2656 20.5594 20.5594C20.2656 20.8531 19.9125 21 19.5 21H4.5ZM4.5 6.5V19.5H19.5V6.5H4.5ZM8.425 15.7962C9.475 16.5154 10.667 16.875 12.001 16.875C13.335 16.875 14.5267 16.5167 15.576 15.8C16.6253 15.0833 17.3917 14.1513 17.875 13.0038C17.3917 11.8563 16.625 10.923 15.575 10.2038C14.525 9.48459 13.333 9.125 11.999 9.125C10.665 9.125 9.47329 9.48333 8.42397 10.2C7.37466 10.9167 6.60833 11.8487 6.125 12.9962C6.60833 14.1437 7.375 15.077 8.425 15.7962ZM11.9971 14.25C11.649 14.25 11.3542 14.1282 11.1125 13.8846C10.8708 13.6409 10.75 13.3451 10.75 12.9971C10.75 12.649 10.8718 12.3542 11.1154 12.1125C11.3591 11.8708 11.6549 11.75 12.0029 11.75C12.351 11.75 12.6458 11.8718 12.8875 12.1154C13.1292 12.3591 13.25 12.6549 13.25 13.0029C13.25 13.351 13.1282 13.6458 12.8846 13.8875C12.6409 14.1292 12.3451 14.25 11.9971 14.25ZM13.42 14.42C13.0333 14.8067 12.56 15 12 15C11.44 15 10.9667 14.8067 10.58 14.42C10.1933 14.0333 10 13.56 10 13C10 12.44 10.1933 11.9667 10.58 11.58C10.9667 11.1933 11.44 11 12 11C12.56 11 13.0333 11.1933 13.42 11.58C13.8067 11.9667 14 12.44 14 13C14 13.56 13.8067 14.0333 13.42 14.42Z'
        fill='#929292'
      />
    </svg>
  );
}

export default PreviewIcon;
