import { useMutation } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

export const useAddExpectedAmountMutation = () => {
  const mutation = useMutation({
    mutationKey: [BillingManagementService.setExpectedAmount.mutationKey],
    mutationFn: BillingManagementService.setExpectedAmount.request,
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: () => {
      SnackbarUtils.success("Expected amount added successfully");
    }
  });

  const onSetExpectedAmount = ({ appointmentFinanceId, expectedAmount }: { appointmentFinanceId: string; expectedAmount: string }) => {
    return mutation.mutateAsync({ appointmentFinanceId, expectedAmount });
  };

  const { isLoading } = useIsLoadingMutation(BillingManagementService.setExpectedAmount.mutationKey);

  return {
    ...mutation,
    onSetExpectedAmount,
    isLoading
  };
};
