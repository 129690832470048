function DownloadIcon() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6906 16.3031C11.7844 16.3406 11.8863 16.3594 11.9963 16.3594C12.1062 16.3594 12.2094 16.3406 12.3056 16.3031C12.4019 16.2656 12.4969 16.2 12.5906 16.1062L16.8375 11.8594C17.0062 11.6906 17.0906 11.4891 17.0906 11.2547C17.0906 11.0203 17.0062 10.8187 16.8375 10.65C16.6687 10.4813 16.4672 10.3969 16.2328 10.3969C15.9984 10.3969 15.7969 10.4813 15.6281 10.65L12.8438 13.4344V3.84375C12.8438 3.60469 12.7641 3.4043 12.6047 3.24258C12.4453 3.08086 12.2454 3 12.0049 3C11.7645 3 11.5629 3.08086 11.4002 3.24258C11.2376 3.4043 11.1562 3.60469 11.1562 3.84375V13.4344L8.4 10.65C8.23125 10.4813 8.02969 10.3969 7.79531 10.3969C7.56094 10.3969 7.35938 10.4813 7.19062 10.65C7.02187 10.8187 6.93281 11.0203 6.92344 11.2547C6.91406 11.4891 6.99375 11.6906 7.1625 11.8594L11.4094 16.1062C11.5031 16.2 11.5969 16.2656 11.6906 16.3031ZM3.50625 20.4937C3.84375 20.8312 4.2375 21 4.6875 21H19.3125C19.7625 21 20.1562 20.8312 20.4937 20.4937C20.8312 20.1562 21 19.7625 21 19.3125V16.1344C21 15.8953 20.9203 15.6949 20.7609 15.5332C20.6016 15.3715 20.4016 15.2906 20.1612 15.2906C19.9207 15.2906 19.7192 15.3715 19.5565 15.5332C19.3938 15.6949 19.3125 15.8953 19.3125 16.1344V19.3125H4.6875V16.1344C4.6875 15.8953 4.60781 15.6949 4.44844 15.5332C4.28906 15.3715 4.08914 15.2906 3.84867 15.2906C3.60822 15.2906 3.40666 15.3715 3.24398 15.5332C3.08133 15.6949 3 15.8953 3 16.1344V19.3125C3 19.7625 3.16875 20.1562 3.50625 20.4937Z'
        fill='#929292'
      />
    </svg>
  );
}

export default DownloadIcon;
