import { useMutation } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { ApiError } from "types";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";

export const useGeneratePayrollsMutation = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: [BillingManagementService.generatePayroll.mutationKey],
    mutationFn: BillingManagementService.generatePayroll.request,
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Payroll generated successfully!");
      navigate(`/${AppRoutes.financials.root}/${AppRoutes.financials.reports}`);
    }
  });

  const onGeneratePayroll = (endDate: string) => {
    mutation.mutate(endDate);
  };

  const { isLoading } = useIsLoadingMutation(BillingManagementService.generatePayroll.mutationKey);

  return {
    ...mutation,
    onGeneratePayroll,
    isLoading
  };
};
