import { useQuery } from "@tanstack/react-query";
import { LookupService } from "api";

export const useDoctorReportsQuery = () => {
  const query = useQuery({
    queryKey: [LookupService.doctorPaymentReports.queryKey],
    queryFn: ({ queryKey }) => {
      return LookupService.doctorPaymentReports.request(queryKey[1]);
    },
    enabled: false
  });

  return {
    ...query,
    doctorReports: query.data?.data ?? []
  };
};
