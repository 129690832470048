import { useMutation } from "@tanstack/react-query";
import { DuplicatePaymentManagementService } from "api";
import { ApiError, DuplicatePaymentForCreateUpdateDto, DuplicatePaymentTransactionForCreateUpdateDto } from "types";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { useNavigate } from "react-router-dom";

export const useCreateDuplicatePaymentMutation = () => {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: [DuplicatePaymentManagementService.create.mutationKey],
    mutationFn: DuplicatePaymentManagementService.create.request,
    onError(error: ApiError) {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess() {
      SnackbarUtils.success("Duplicate Payment created successfully!");
      navigate(-1);
    }
  });

  const calculateRemainingAmount = (collectedAmount: number, paymentTransactions: DuplicatePaymentTransactionForCreateUpdateDto[]) => {
    const total = +collectedAmount || 0;
    const usedAmount = paymentTransactions?.reduce((acc, transaction) => acc + (+transaction.paidAmount || 0), 0) || 0;
    return total - usedAmount;
  };

  const onCreateDuplicatePayment = (payment: DuplicatePaymentForCreateUpdateDto) => {
    const remaining = calculateRemainingAmount(payment.collectedAmount, payment.transactions);
    if (remaining < 0) {
      SnackbarUtils.error("The total distributed amount across appointments must not exceed the total payment amount.");
      return;
    }
    if (remaining > 0) {
      SnackbarUtils.error("The total distributed amount across appointments must not be less than the total payment amount.");
      return;
    }
    mutation.mutate(payment);
  };

  const { isLoading } = useIsLoadingMutation(DuplicatePaymentManagementService.create.mutationKey);

  return {
    ...mutation,
    onCreateDuplicatePayment,
    isLoading
  };
};
