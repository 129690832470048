import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, PaidDateForUpdateDto } from "types";

export const useOverridePaidMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [BillingManagementService.overridePaidDate.mutationKey],
    mutationFn: BillingManagementService.overridePaidDate.request,
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: async () => {
      SnackbarUtils.success("Paid Date has been updated successfully!");
      await queryClient.invalidateQueries({
        queryKey: [BillingManagementService.doctorPaymentReports.queryKey]
      });
    }
  });

  const onOverridePaidDateAmount = (data: PaidDateForUpdateDto) => {
    return mutation.mutateAsync(data);
  };

  const { isLoading } = useIsLoadingMutation(BillingManagementService.overridePaidDate.mutationKey);

  return {
    ...mutation,
    onOverridePaidDateAmount,
    isLoading
  };
};
