type MoneyFormatOptions = {
  currencySymbol?: string;
  decimalPlaces?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
};
export default class FormatUtils {

  static formatDropdownOptions<T extends Record<string, any>>(valueKey: keyof T, textKey: keyof T, list?: T[]): Array<{ text: string; value: string }> {
    if (!list) return [];
    return (
      list?.map(item => {
        return {
          text: item[textKey],
          value: item[valueKey]
        };
      }) ?? []
    );
  }

  static fromPascalToNormal(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, "$1 $2").trim(); // Remove any leading/trailing spaces
  }

  static formatFaxPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) return "";
    // Check if the phone number is already in the desired format "(XXX) XXX XXXX"
    const desiredFormatRegex = /^\(\d{3}\) \d{3} \d{4}$/;
    if (desiredFormatRegex.test(phoneNumber)) {
      return phoneNumber;
    }

    // Convert from "XXX-XXX-XXXX" to "(XXX) XXX XXXX"
    const conversionRegex = /^(\d{3})-(\d{3})-(\d{4})$/;
    const match = phoneNumber.match(conversionRegex);
    if (match) {
      const [, areaCode, firstPart, secondPart] = match;
      return `(${areaCode}) ${firstPart} ${secondPart}`;
    }

    throw new Error("Invalid phone number format");
  }

  static formatMoneyAdvanced(amount: number, options: MoneyFormatOptions = {}): string {
    if (!amount) return "$0.00";

    const { currencySymbol = "$", decimalPlaces = 2, thousandsSeparator = ",", decimalSeparator = "." } = options;

    const parts = (+amount)?.toFixed(decimalPlaces).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    return `${currencySymbol}${parts.join(decimalSeparator)}`;
  }
}
