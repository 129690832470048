import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { RouteObject } from "react-router-dom";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const DoctorFolderDetailsPage = WithLoadable(lazy(() => import("pages/doctors/DoctorFolderDetailsPage")));
const DoctorListPage = WithLoadable(lazy(() => import("pages/doctors/DoctorListPage")));
const DoctorProfilePage = WithLoadable(lazy(() => import("pages/doctors/DoctorProfilePage")));
const DoctorCreatePage = WithLoadable(lazy(() => import("pages/doctors/DoctorCreatePage")));
const DoctorUpdatePage = WithLoadable(lazy(() => import("pages/doctors/DoctorUpdatePage")));

export const DoctorRoutes: RouteObject = {
  path: AppRoutes.doctors.root,
  element: <AuthorizationGuard permissions={[AppPermissions.DoctorManagement.Actions.View]} />,
  children: [
    {
      index: true,
      element: <DoctorListPage />
    },
    {
      path: `${AppRoutes.doctors.profile}/:doctorId`,
      children: [
        { index: true, element: <DoctorProfilePage /> },
        {
          path: `${AppRoutes.folders.root}/:folderId`,
          element: <DoctorFolderDetailsPage />
        }
      ]
    }
  ]
};

export const DoctorFormsRoutes: RouteObject = {
  path: AppRoutes.doctors.root,
  element: <FormLayout />,
  children: [
    {
      path: AppRoutes.doctors.create,
      element: <AuthorizationGuard permissions={[AppPermissions.DoctorManagement.Actions.Create]} />,
      children: [{ index: true, element: <DoctorCreatePage /> }]
    },
    {
      path: `${AppRoutes.doctors.update}/:doctorId`,
      element: <AuthorizationGuard permissions={[AppPermissions.DoctorManagement.Actions.Update]} />,
      children: [{ index: true, element: <DoctorUpdatePage /> }]
    }
  ]
};
