import { BillingManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks";
import { useParams } from "react-router-dom";

export const useAppointmentFinancesProfileListQuery = () => {
  const { appointmentFinancialId } = useParams<{ appointmentFinancialId: string }>();
  const query = usePaginatedDataTableQuery({
    queryFn: BillingManagementService.appointmentFinancePaymentTransactions.request,
    queryKey: BillingManagementService.appointmentFinancePaymentTransactions.queryKey,
    initialColumnFilters: [
      {
        id: "appointmentFinanceIdsFilter",
        value: appointmentFinancialId
      }
    ],
    queryOptions: {
      enabled: !!appointmentFinancialId
    }
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
