import { useQuery } from "@tanstack/react-query";
import { BillingManagementService } from "api";
import { useParams } from "react-router-dom";

export const useAppointmentFinancesProfileQuery = () => {
  const { appointmentFinancialId } = useParams<{ appointmentFinancialId: string }>();
  const query = useQuery({
    queryKey: [BillingManagementService.appointmentFinanceProfile.queryKey, appointmentFinancialId],
    queryFn: () => BillingManagementService.appointmentFinanceProfile.request(appointmentFinancialId!),
    enabled: !!appointmentFinancialId
  });

  return {
    ...query
  };
};
